import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@app/core/services/alert.service';
import { AuthService } from '@app/core/services/auth.service';
import { ModalService } from '@app/core/services/modal.service';
import { ValidationService } from '@app/core/services/validation.service';
import { validateForm } from '@app/shared/helpers/validate-form';

@Component({
  selector: 'auth-modal-without-continue-logout',
  templateUrl: './auth-modal-without-continue-logout.component.html',
  styleUrls: ['./auth-modal-without-continue-logout.component.scss']
})
export class AuthModalWithoutContinueLogoutComponent implements OnInit {
  public isLoggingIn: boolean = false;
  public loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, ValidationService.emailValidator]],
    password: ['', [Validators.required]],
  });

  public  linkToRedirect: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {}

  onSubmit(e: Event) {
    validateForm(this.loginForm);
    if (this.loginForm.valid) {
      this.isLoggingIn = true;
      this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe(next => {
        this.next(this.linkToRedirect);
        this.isLoggingIn = false;
      }, err => {
        this.isLoggingIn = false;
        if (err.status === 400) {
          this.alertService.danger('Email or password is invalid', 2000);
        }
      });
    }
  }

  next(navigateUrl?:string) {
    this.modalService.close('auth-modal-without-continue-logout');
    if(navigateUrl) {
      this.router.navigateByUrl(navigateUrl);
      return;
    }

    if(this.authService.authModalCallback) this.authService.authModalCallback();
  }
}
