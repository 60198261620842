import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RequestService} from "@app/core/services/request.service";
import {Observable} from "rxjs";
import {UserActionTypeEnum} from "@shared/const/user-action-type-enum";
import {UserSettingsModel} from "@shared/models/user-settings.model";
import {ValueModel} from "@shared/models/value.model";
import {PersonalGardenCheckModel} from "@shared/models/personal-garden-check.model";

@Injectable({
  providedIn: 'root'
})
export class UserService extends RequestService {
  private url = super.apiUrl + 'user';

  constructor(private http: HttpClient) {
    super();
  }

  registerFamily(request: object): Observable<void> {
    return this.http.post<void>(
      `${this.url}/register/family`,
      request,
      this.httpOptionsWithCredentials
    );
  }

  registerBusiness(request: object): Observable<void> {
    return this.http.post<void>(
      `${this.url}/register/business`,
      request,
      this.httpOptionsWithCredentials
    );
  }

  logUserAction(actionType: UserActionTypeEnum): Observable<void> {
    return this.http.post<void>(
      `${this.url}/log`,
      {actionType: actionType},
      this.httpOptionsWithCredentials
    )
  }

  getSettingsInfo(): Observable<UserSettingsModel> {
    return this.http.post<UserSettingsModel>(
      `${this.url}/get_settings`,
      {},
      this.httpOptionsWithCredentials
    );
  }

  saveSettings(request: any): Observable<void> {
    return this.http.post<void>(
      `${this.url}/save_settings`,
      request,
      this.httpOptionsWithCredentials
    );
  }

  setActionAsOrganisation(setActAsOrganisation: boolean, shopBasketHash: string | null): Observable<void> {
    return this.http.post<void>(
      `${this.url}/set_act`,
      {"setActAsOrganisation": setActAsOrganisation, "shopBasketHash": shopBasketHash},
      this.httpOptionsWithCredentials
    );
  }

  resetPassword(email: any): Observable<void> {
    var request = {"value": email};
    return this.http.post<void>(
      `${this.url}/reset_password`,
      request,
      this.httpOptionsWithCredentials
    );
  }

  setNewPassword(request: any): Observable<void> {
    return this.http.post<void>(
      `${this.url}/change_password`,
      request,
      this.httpOptionsWithCredentials
    );
  }


  changePassword(request: any): Observable<void> {
    return this.http.post<any>(
      `${this.url}/set_new_password`,
      request
    );
  }

  getEmailOneTimeHash(hash: string | null): Observable<ValueModel> {
    return this.http.get<any>(
      `${this.url}` + '/get_email_one_time_hash/' + hash,
      this.httpOptionsWithCredentials
    );
  }

  loginCheck(): Promise<void> {
    return this.http.post<void>(
      `${this.url}/login_check`,
      {},
      this.httpOptionsWithCredentials
    ).toPromise();
  }

  loginCheckPositive(): Promise<any> {
    return this.http.post<any>(
      `${this.url}/positive_login_check`,
      {},
      this.httpOptionsWithCredentials
    ).toPromise();
  }

  getPersonalGarden(): Observable<PersonalGardenCheckModel> {
    return this.http.post<PersonalGardenCheckModel>(
      `${this.url}/get_personal_garden`,
      {},
      this.httpOptionsWithCredentials
    );
  }

  impersonateMode(hash: string): Observable<any> {
    return this.http.post<any>(
      `${this.url}/impersonate`,
      {hash: hash},
      this.httpOptionsWithCredentials
    );
  }
}
