<div id="greenie">
  <div class="evabot-button evabot-anim-wiggle">
    <button>
      <img src="assets/images/greenie.png"/>
    </button>
  </div>
  <div
    [id]="chatboxId"
    style="display: none"
    class="evabot-anim-fadeInUp evabot-chatbox"
  >
    <div class="evabot-chatbox-header">
      <img src="https://playitgreen.com/wp-content/uploads/2022/10/pig-logo-white-sm-o.png">
      <div class="evabot-header-text">
        <span>Hi there!</span>
        <img class="evabot-anim-wiggle" src="https://twemoji.maxcdn.com/v/latest/svg/1f44b.svg">
      </div>
      <button class="evabot-chatbox-close">
        <span class="evabot-chatbox-close-content"></span>
      </button>
    </div>
    <div class="evabot-chatbox-content">
      <div class="evabot-chatbox-messages"></div>
      <div class="evabot-chatbox-control">
        <form>
          <div class="evabot-chatbox-input">
            <input [placeholder]="this.config.placeholderText"/>
          </div>
          <button class="evabot-chatbox-button" type="submit">
            {{this.config.buttonSendText}}
          </button>
        </form>
      </div>
    </div>
    <div class="evabot-info">Powered by <a href="https://evobot2.pl/"><img src="https://evobot2.pl/src/images/logo.png"
                                                                           alt="evobot2.pl"></a></div>
  </div>
</div>
