import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RequestService} from "@app/core/services/request.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GreenieService extends RequestService {
  private url = super.apiUrl + 'greenie';

  constructor(private http: HttpClient) {
    super();
  }

  createSession(): Observable<any> {
    return this.http.post<any>(
      `${this.url}/create_session`,
      { },
      this.httpOptionsWithCredentials
    );
  }

  sendText(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.url}/send_text`,
      data,
      this.httpOptionsWithCredentials
    );
  }

  loadSession(hash: string): Observable<any> {
    return this.http.get<any>(
      `${this.url}/get_full_conversation/` + hash,
      this.httpOptionsWithCredentials
    );
  }

}
