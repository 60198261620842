import {
    trigger,
    state,
    style,
    animate,
    transition } from '@angular/animations';

export const fadeInAnimation = trigger('FadeIn', [
  state('in', style({opacity: 1})),

  transition(':enter', [
    style({opacity: 0}),
    animate(300)
  ]),

  transition(':leave', style({opacity: 0}))
]);

export const fadeInOutAnimation = trigger('FadeInOut', [
  state('in', style({opacity: 1})),
  transition(':enter', [
    style({opacity: 0}),
    animate(400)
  ]),
  transition(':leave',
    animate(300, style({opacity: 0}))
  )
]);

export const fadeAnimation = (valIn:number = 400, valOut:number = 300) => {
  return trigger('FadeInOut', [
    state('in', style({opacity: 1})),
    transition(':enter', [
      style({opacity: 0}),
      animate(valIn)
    ]),
    transition(':leave',
      animate(valOut, style({opacity: 0}))
    )
  ]);
}

export const fadeInOutTransformAnimation = trigger('FadeInOutTransform', [
  state('in', style({opacity: 1, transform: 'none'})),
  transition(':enter', [
    style({opacity: 0, transform: 'translateX(100px)'}),
    animate('1s ease')
  ]),
  transition(':leave',
    animate('1s ease', style({opacity: 0, transform: 'translateX(100px)'}))
  )
]);