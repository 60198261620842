import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  static getMessage(validatorName: string, validatorValue?: any) {
    const config: any = {
      required: 'Field is required.',
      requiredTrue: 'The box must be checked.',
      min: `The value shall not be less than ${validatorValue.min}.`,
      max: `The value cannot be greater than ${validatorValue.max}.`,
      maxlength: `The value cannot be more than ${validatorValue.requiredLength} chars.`,
      minlength: `The value cannot be less than ${validatorValue.requiredLength} chars.`,
      invalidPassword: "Password doesn't meet the minimum requirements. Password must contain at least 8 characters and at least one capital letter, one number, one special character.",
      invalidEmail: 'Email address is invalid.',
      invalidPhone: 'Invalid characters in Phone number field.',
      invalidPostCode: 'Zip code is incorrect.',
      valueNotMatch: 'Values vary.',
      valueShouldMatch: 'The values must be different from each other.',
      invalidName: 'Only letters, spaces and apostrophes are allowed',
      custom: validatorValue
    };

    return config[validatorName];
  }

  static passwordValidator(control: FormControl) {
    if(!control.value) return null;

    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(control.value)) return { invalidPassword: true };

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(control.value)) return { invalidPassword: true };

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(control.value)) return { invalidPassword: true };

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(control.value)) return { invalidPassword: true };

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_])/;
    if (!isContainsSymbol.test(control.value)) return { invalidPassword: true };

    const isValidLength = /^.{8,100}$/;
    if (!isValidLength.test(control.value)) return { invalidPassword: true };

    return null;
  }

  static phoneValidator(control: FormControl) {
    if(control.value) {
      return control.value?.match(/^\+?(?:\d{1,3})?[0-9- ]{7,20}$/) ? null : { invalidPhone: true }
    } else {
      return null;
    }
  }

  static emailValidator(control: AbstractControl) {
    return control.value?.match(/^\S+@\S+\.\S+$/) ? null : { invalidEmail: true }
  }

  static nameValidator(control: AbstractControl) {
    return control.value?.match(/^[^*|\":<>[\]{}`\\()\d;@&$]+$/) ? null : { invalidName: true }
  }

  static trimmableEmailValidator(control: AbstractControl) {
    return control.value?.match(/^\s*\S+@\S+\.\S+\s*$/) ? null : { invalidEmail: true }
  }

  static matchValue(mustMatch: boolean, controlNameA: string, controlNameB: string, customMessage?: string) {
    return (c: AbstractControl) => {
      if (!c.get || (!c.get(controlNameA)?.dirty && !c.get(controlNameB)?.dirty)) return;

      if (mustMatch && c.get(controlNameA)?.value !== c.get(controlNameB)?.value) {
        return { valueNotMatch: customMessage ? customMessage : true };
      }

      if (!mustMatch && c.get(controlNameA)?.value === c.get(controlNameB)?.value) {
        return { valueShouldMatch: customMessage ? customMessage : true };
      }
    }
  }

  static postCode(control: FormControl) {
    return control.value && control.value.match(/\d{2}-\d{3}/) ? null : { invalidPostCode: true }
  }

  static maxAmount(maxAmount: number) {
    return (c: AbstractControl) => {
      return c.value > maxAmount ? { maxAmount: { max: maxAmount, actual: c.value } } : null
    }
  }
}
