import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { fadeInOutAnimation } from '@app/shared/helpers/animations';

@Component({
    selector: 'control-message',
    animations: [fadeInOutAnimation],
    template: '<div #content><ng-content></ng-content></div>'
})
export class ControlMessageComponent implements AfterViewInit {
    @ViewChild('content') content: ElementRef;
    public message: string;

    ngAfterViewInit() {
        this.message = this.content.nativeElement.innerHTML;
    }
}