import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmService } from '@app/core/services/confirm.service';
import { ModalService } from '@app/core/services/modal.service';
import { Subscription } from 'rxjs';
import { Confirm } from './confirm.types';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public confirm:Confirm;

  constructor(private modalService:ModalService, private confirmService:ConfirmService) { }

  ngOnInit(): void {
    this.subscription = this.confirmService.getObservable().subscribe((confirm:Confirm) => this.onOpen(confirm));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onOpen(confirm:Confirm) {
    this.confirm = confirm;
    this.modalService.open('app-confirm');
  }

  onCancel() {
    if(this.confirm.onCancelCallback) this.confirm.onCancelCallback();
    this.modalService.close('app-confirm');
  }

  onConfirm() {
    this.confirm.onConfirmCallback();
    this.modalService.close('app-confirm');
  }

}
