import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateUtil } from '@app/shared/helpers/date-util';
import { inputType } from './input.types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() additionalClasses = '';
  @Input() type: inputType = 'text';
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() isPasswordVisible: boolean = false;
  @Input() readonly: boolean;
  @Input() value: any;
  @Input() fileType: string;
  @Input() hidden:boolean = false;
  @Input() modifyValueCallback: (args: any) => any = (args: any) => {return args};
  @Input() icon:string;
  @Input() iconLeft:boolean;
  @Input() mask: string;
  @Input() maskPrefix: string = '';
  @Input() maskSuffix: string = '';
  @Input() specialCharacters: string[];
  @Input() dropSpecialCharacters: boolean = false;

  @Output() onChangeEvent = new EventEmitter<any>();

  private file: File;
  get fileName() {
    return this.file != null ? this.file.name : null;
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    if (file != null) {
      this.onChange(file);
      this.file = file;
    }
  }

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

  constructor(public datePipe: DatePipe) { }

  click() {
    this.inputElement.nativeElement.click();
  }

  ngOnInit() {
    if(this.type === 'date') {
      this.readonly = true;
    }

    if(this.type === 'time') {
      this.mask = 'Hh:m0';
      this.specialCharacters = [':'];
    }
  }

  // Reactive Forms Handler:
  propagateChange = (newValue: any) => {};

  onChange = (newValue: any) => {
    if(this.type != 'date') {
      this.value = newValue = this.modifyValueCallback(newValue);
    }
    this.propagateChange(newValue);
    if (this.onChangeEvent) {
      this.onChangeEvent.emit(newValue);
    }
  }
  onTouched = () => {};

  onBlur = () => {
    if(this.type === 'time' && !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.value)) {
      console.log(this.value)
      this.writeValue(null);
    }

    if(this.dropSpecialCharacters) {
      this.writeValue(this.value.replace(/[^a-zA-Z0-9]/g, ''))
    }

    this.onTouched();
  }

  registerOnChange(onChange: any) {
    this.propagateChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this.value = value;

    if(value && this.type === 'date') {
      this.onDateChange(value);
    }
  }

  // Functionallity
  changePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  chooseFile() {
    this.inputElement.nativeElement.click();
  }

  public copying: boolean;
  copyToClipboard() {
    this.copying = true;
    setTimeout(() => this.copying = false, 2000);
    const el = document.createElement('textarea');
    el.value = this.value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // Datepicker

  public isDatePickerVisible: boolean = false;

  @Input() minDate: Date | null;
  @Input() maxDate: Date | null;
  @Input() selectedDate: Date;

  onFocus() {
    if(this.type === 'date'){
      this.onDatepickerActiveChange(true);
    }
  }

  onDateChange(date: Date) {
    this.value = DateUtil.formatDate(date);
    this.onChange(date);
    this.isDatePickerVisible = false;
  }

  onDatepickerActiveChange(isToggle: boolean) {
    this.isDatePickerVisible = isToggle;
  }
}
