import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '@app/shared/models/user.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '@environment/environment';


import {HttpClient} from "@angular/common/http";
import {RequestService} from "@app/core/services/request.service";
import {map} from "rxjs/operators";
import {UserRole} from '@app/shared/const/user-role-enum';
import {GardenType} from "@shared/const/garden-type-enum";
import {CookieService} from "ngx-cookie-service";
import {UserService} from "@app/core/services/user.service";
import {CartService} from "@app/core/services/cart.service";
import {LocalStorageService} from './local-storage.service';
import {AppComponent} from '@app/app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends RequestService {
  public authModalCallback?: Function;
  public userSubject: BehaviorSubject<UserModel | null>;
  public user: Observable<UserModel | null>;
  public get userRole(): UserRole {
    return this.userSubject.getValue()?.roles as UserRole;
  }
  private actAsOrganisationEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router,
              private http: HttpClient,
              private cookieService: CookieService,
              private userService: UserService,
              private cartService : CartService,
              private localStorage:LocalStorageService
              ) {
    super();
    AppComponent.isBrowser.subscribe(isBrowser => {
      if(isBrowser) {
        this.userSubject = new BehaviorSubject<UserModel | null>(this.getUserFromStorage());
      } else {
        this.userSubject = new BehaviorSubject<UserModel | null>(null);
      }
      this.user = this.userSubject.asObservable();
    })
  }

  public isMyGarden(hash:string) {
    return hash == this.userSubject.getValue()?.privateGarden;
  }

  public isMyMasterGarden(hash:string) {
    return hash == this.userSubject.getValue()?.masterGarden;
  }

  public loginCheck(callbackSuccess: () => void, callbackFail: () => void): void {
    this.userService.loginCheck().then(value => {
      callbackSuccess();
    }, error => {
      callbackFail();
    });
  }

  public discoveryCheck() {
    const user = this.userSubject.getValue();
    if (user != null) {
      return user?.privateGardenId != null;
    } else {
      return false;
    }
  }

  public loginCheckIfUserLoggedWithError(callbackSuccess: () => void, callbackFail: () => void) {
    if (this.isUserLoggedIn()) {
      this.loginCheck(callbackSuccess, callbackFail);
    } else {
      callbackFail();
    }

  }

  public getSelectedGardenType() {
    const user = this.userSubject.getValue();
    let selectedGardenType = GardenType.MASTER;
    if (user && !user.actAsOrganisation && user.privateGarden != null) {
      selectedGardenType = GardenType.PERSONAL;
    }

    return selectedGardenType;
  }

  public isUserLoggedIn(): boolean {
    return this.userSubject.getValue() != null;
  }

  public isActAsOrganisation(): boolean {
    const user = this.userSubject.getValue();
    return user != null ? user.actAsOrganisation : false;
  }

  public setActAsOrganisation(actAsOrganisation: boolean, callback: () => void): void {
    const user = this.userSubject.getValue();
    if (user != null && user.actAsOrganisation != actAsOrganisation) {
      this.actAsOrganisationEmitter.emit(actAsOrganisation);
      user.actAsOrganisation = actAsOrganisation;
      this.userSubject.next(user);
      this.setUserToStorage(user);
      if ((this.userRole !== UserRole.STANDARD_MEMBER && this.userRole !== UserRole.MASTER_MEMBER)) {
        const shopBasketHash = sessionStorage.getItem('basketHash');
        this.userService.setActionAsOrganisation(actAsOrganisation, shopBasketHash).subscribe(() => {
          callback();
        });
      }
    }
  }

  public login(email: string, password: string) {
    let postData: string = 'username=' + encodeURIComponent(email) +
      '&password=' + encodeURIComponent(password);
    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        postData += '&ua=' + window.navigator.userAgent;
      }
    });
    let loginUrl = 'login';

    return this.http.post<any>(
      environment.apiUrl + loginUrl,
      postData,
      this.httpOptionsWithoutCredentials
    ).pipe(
      map(
        response => {
          this.mapUserData(response, false);
          this.cartService.setUserInBasket();
        }
      )
    );
  }

  public mapUserData(response: any, isImpersonateMode: boolean) {
    const user = new UserModel();
    user.email = response.email;
    user.firstname = response.firstName;
    user.lastname = response.lastName;
    user.token = response.token;
    user.roles = response.roles;
    user.masterGarden = response.masterGarden;
    user.privateGarden = response.privateGarden;
    user.masterGardenId = response.masterGardenId;
    user.privateGardenId = response.privateGardenId;
    user.actAsOrganisation = response.actAsOrganisation;
    user.isImpersonateMode = isImpersonateMode;

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('JSESSIONID', user.token);
    let date = new Date();
    this.cookieService.set('JSESSIONID', user.token, date.setDate(date.getDate() + 1),  "/", 'playitgreen.com')
    this.cookieService.set('user', JSON.stringify(user), date.setDate(date.getDate() + 1),  "/", 'playitgreen.com')
    this.userSubject.next(user);
  }

  public clearUserInfo() {
    this.localStorage.removeItem('user');
    this.localStorage.removeItem('JSESSIONID');
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/', 'playitgreen.com');
    this.userSubject.next(null);
  }

  public logout() {
    this.logoutRequest().subscribe(() => {
    });
    this.clearUserInfo();
    this.router.navigateByUrl('/login');
  }

  public getActAsOrganisationEmitter(): EventEmitter<boolean> {
    return this.actAsOrganisationEmitter;
  }

  public getMasterAndPrivateForestGardenHashes(): string[] {
    const user = this.userSubject.getValue();
    const result: string[] = [];
    if (user != null) {
      if (user.masterGarden != null) {
        result.push(user.masterGarden);
      }

      if (user.privateGarden != null) {
        result.push(user.privateGarden);
      }
    }
    return result;
  }

  public getHashActualSelectedForestGarden(): string {
    const user = this.userSubject.getValue();
    if (user) {
      return user.actAsOrganisation ? user.masterGarden : user.privateGarden;
    }

    return '';
  }

  public getIdActualSelectedForestGarden(): number {
    const user = this.userSubject.getValue();
    if (user) {
      return user.actAsOrganisation ? Number(user.masterGardenId) : Number(user.privateGardenId);
    }

    return 0;
  }

  public isOwnerForestGardenId(idForestGarden: number): boolean {
    return this.getIdActualSelectedForestGarden() == idForestGarden;
  }

  public isOwnerForestGardenHash(hashForestGarden: string): boolean {
    return this.getHashActualSelectedForestGarden() == hashForestGarden;
  }

  private logoutRequest(): Observable<void> {
    return this.http.post<void>(
      environment.apiUrl + 'logout',
      {},
      this.httpOptionsWithCredentials
    );
  }

  private getUserFromStorage(): UserModel | null {
    return JSON.parse(this.localStorage.getItem('user')!);
  }

  public setUserToStorage(user: UserModel): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public licenseCheck(callback: () => void): void {
    const user = this.userSubject.getValue();
    if (user != null) {
      this.userService.getPersonalGarden().subscribe(val => {
        if (user.privateGarden != val.privateGarden) {
          user.privateGardenId = val.privateGardenId;
          user.privateGarden = val.privateGarden;
          this.userSubject.next(user);
          this.setUserToStorage(user);
        } else {
          callback();
        }

      }, error => {
        callback();
      });
    } else {
      callback();
    }
  }

  public isStandardUserActingAsOrg(): boolean {
    return this.userRole === UserRole.STANDARD_MEMBER && this.isActAsOrganisation();
  }

  public isImpersonateMode(): boolean {
    const user = this.userSubject.getValue();
    if (user) {
      return user.isImpersonateMode;
    }

    return false;
  }

  isStandardUserForThisGarden(forestGardenHash: string) {
    return this.isMyMasterGarden(forestGardenHash) && this.isStandardUserActingAsOrg();
  }
}
