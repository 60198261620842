import { Component, Input, OnInit } from '@angular/core';
import { iconType, iconSize, iconColor } from './icon.types';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() name: string;
  @Input() src?: string;
  @Input() type: iconType;
  @Input() size: iconSize;
  @Input() background: number|'auto'|undefined;
  @Input() color:iconColor|undefined;
  @Input() backgroundColor:iconColor = 'default';

  public backgroundShape:number;

  constructor() {}

  ngOnInit(): void {
    if(this.background === 'auto') {
      this.backgroundShape = Math.floor(Math.random() * 6) + 1;
    } else if(this.background) {
      this.backgroundShape = this.background;
    }
  }

  getClasses(): string {
    let classes:string[] = [];
    classes.push(this.type ? "material-icons-" + this.type : "material-icons");
    if(this.color) classes.push("app-icon-color-" + this.color);
    if(this.background) classes.push("app-icon-background-" + this.backgroundColor);
    if(this.size && ['sm', 'md', 'lg'].includes(this.size)) classes.push(`app-icon-${this.size}`);
    return classes.join(' ');
  }

  getStyles(): string {
    let styles:string[] = [];
    if(this.size && !['sm', 'md', 'lg'].includes(this.size)) styles.push(`font-size:${this.size}`);
    return styles.join(';');
  }
}
