import { Injectable } from '@angular/core';
import { Alert, AlertType } from '@app/shared/components/_ui/alert/alert.types';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  
  private subject = new Subject<Alert>();
  private id = 0;

  constructor() { }

  getObservable(): Observable<Alert> {
    return this.subject.asObservable();
  }

  info(message: string, timeout = 3000) {
    this.subject.next(new Alert(this.id++, AlertType.info, message, timeout));
  }

  success(message: string, timeout = 3000) {
    this.subject.next(new Alert(this.id++, AlertType.success, message, timeout));
  }

  warning(message: string, timeout = 3000) {
    this.subject.next(new Alert(this.id++, AlertType.warning, message, timeout));
  }

  danger(message: string, timeout = 0) {
    this.subject.next(new Alert(this.id++, AlertType.danger, message, timeout));
  }

}
