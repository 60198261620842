import {Observable} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from "@app/core/services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            if (localStorage.getItem('user') != null) {
              this.authService.clearUserInfo();
              if (this.router.routerState.snapshot.url.includes('redirectUrl')) {
                this.router.navigate([this.router.routerState.snapshot.url]);
              } else {
                this.router.navigate(['login/'], {queryParams: {redirectUrl: this.router.routerState.snapshot.url}});
              }
            } else {
              this.router.navigate(['login/']);
            }
          }
          throw err;
        })
      );
  }
}
