<span class="app-icon" [ngClass]="{'icon-with-bg': background}"  [style]="getStyles()" [class]="getClasses()">
    <span *ngIf="!src">{{name}}<ng-content *ngIf="!name"></ng-content></span>
    <svg-icon *ngIf="src" [src]="src" [svgStyle]="{ 'fill': 'inherit', 'height': this.size, 'width': this.size }"></svg-icon>
    
    <div *ngIf="background" class="app-icon-bg">
        <svg *ngIf="backgroundShape === 1" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M53.5159 0C115.577 0 160.5 38.194 160.5 80.9402C160.5 123.146 114.793 160 53.5159 160C4.13963 160 0.5 114.949 0.5 80.9402C0.5 46.2632 3.16972 0 53.5159 0Z" fill="#F1F1F1"/>
        </svg>
        <svg *ngIf="backgroundShape === 2" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M79.1205 0C115.15 0 160 13.8849 160 73.7317C160 136.082 116.657 160 79.1205 160C42.1598 160 0 135.125 0 73.7317C0 14.8827 43.6914 0 79.1205 0Z" fill="#F1F1F1"/>
        </svg>
        <svg *ngIf="backgroundShape === 3" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M70.0877 0C122.239 0 160 44.1108 160 93.4915C160 135.682 114.645 160 70.0877 160C31.3374 160 0 130.183 0 93.4915C0 48.7173 22.8018 0 70.0877 0Z" fill="#F1F1F1"/>
        </svg>
        <svg *ngIf="backgroundShape === 4" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M59.4357 0C110.514 0 160.5 31.9217 160.5 82.9607C160.5 132.564 109.077 160 59.4357 160C21.5375 160 0.500122 120.83 0.500122 82.9607C0.500122 43.2297 19.6741 0 59.4357 0Z" fill="#F1F1F1"/>
        </svg>
        <svg *ngIf="backgroundShape === 5" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M68.1155 0C126.943 0 160.5 46.9241 160.5 92.8135C160.5 131.291 117.441 160 68.1155 160C25.3535 160 0.5 126.171 0.5 92.8135C0.5 51.1236 14.6717 0 68.1155 0Z" fill="#F1F1F1"/>
        </svg>
        <svg *ngIf="backgroundShape === 6" width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="icon-shape" fill-rule="evenodd" clip-rule="evenodd" d="M53.5159 0C115.577 0 160.5 38.194 160.5 80.9402C160.5 123.146 114.793 160 53.5159 160C4.13963 160 0.5 114.949 0.5 80.9402C0.5 46.2632 3.16972 0 53.5159 0Z" fill="#F1F1F1"/>
        </svg>
    </div>
</span>