import { ButtonColor } from "../button/button.types";

export class Confirm {
    constructor(
        public text: string,
        public onConfirmCallback: Function,
        public onCancelCallback?: Function,
        public options?: ConfirmOptions
    ) { }
}

export type ConfirmOptions = {
  showCancelButton?: boolean,
  confirmButtonText?: string,
  cancelButtonText?: string,
  cancelButtonColor?: ButtonColor,
  confirmButtonColor?: ButtonColor
}
