<div @FadeInOut *ngIf="isOpen" class="app-modal-overlay"></div>
<div @FadeInOut *ngIf="isOpen" class="app-modal" title="">
    <div class="app-modal-container">
        <div class="app-modal-body" [ngClass]="{'transparent': transparent}">
            <h4 *ngIf="title" [innerHTML]="title"></h4>
            <div class="app-modal-content">
                <ng-content></ng-content>
            </div>
            <app-icon *ngIf="canClose" class="app-modal-close" (click)="close()">close</app-icon>
        </div>
    </div>
    <div (click)="close()" class="app-modal-toggle"></div>
</div>
