import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RequestService} from "@app/core/services/request.service";
import {Observable} from "rxjs";
import {ResponseListModel} from "@shared/models/response-list.model";
import {ShopItem} from "@shared/models/shop-item.model";
import {ValueModel} from "@shared/models/value.model";
import {DictionaryRowModel} from "@shared/models/dictionary-row.model";
import {ProductCarouselListModel} from "@shared/models/product-carousel-list.model";
import {CategoryGroupItemModel} from "@shared/models/category-group-item.model";
import {ShopSearchItemModel} from "@shared/models/shop-search-item.model";
import { ProductDetailsEntity } from "@app/shared/models/product-details-entity";

@Injectable({
  providedIn: 'root'
})
export class ShopService extends RequestService {
  private url = super.apiUrl;

  constructor(private http: HttpClient) {
    super();
  }
  createBasket(): Observable<ValueModel> {
    return this.http.post<ValueModel>(
      `${this.url}basket/create`,
      {},
      this.httpOptionsJsonWithoutCredentials
    );
  }

  addProductToBasket(request : any): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/add_product`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  getItems(request: ShopSearchItemModel): Observable<ResponseListModel<ShopItem>> {
    return this.http.post<ResponseListModel<ShopItem>>(
      super.apiUrl +`product/list`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  getItemsToCarousel(): Observable<ProductCarouselListModel[]> {
    return this.http.get<ProductCarouselListModel[]>(
      super.apiUrl +`product/shop/carousel`,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  getManufactures(): Observable<DictionaryRowModel[]> {
    return this.http.get<DictionaryRowModel[]>(
      super.apiUrl +`product/manufacturers`,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  getProductCategory(): Observable<CategoryGroupItemModel[]> {
    return this.http.get<CategoryGroupItemModel[]>(
      super.apiUrl +`product/get_categories`,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  getProductDetails(productId:number): Observable<ProductDetailsEntity> {
    return this.http.post<ProductDetailsEntity>(`${this.url}product/get_full_product_info`, {id: productId}, this.httpOptionsJsonWithoutCredentials);
  }
}
