import { NgModule } from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ErrorInterceptor} from "@shared/interceptors/ErrorInterceptor";
import { MetaModule } from 'ng2-meta';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    MetaModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
      Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
