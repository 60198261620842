import {Component, Input} from '@angular/core';
import {iconSize, iconType} from '../icon/icon.types';
import {ButtonColor, ButtonSize, ButtonType, ButtonVariant} from './button.types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() type: ButtonType = 'button';
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() icon: string;
  @Input() size: ButtonSize = 'md';
  @Input() iconSize: iconSize;
  @Input() iconType: iconType;
  @Input() variant: ButtonVariant = 'solid';
  @Input() color: ButtonColor = 'default';
  @Input() iconLeft: boolean = false;

  constructor() {
  }

  getClasses() {
    let classes: string[] = ['app-btn'];
    if (this.color) {
      classes.push("btn-color-" + this.color);
    }

    classes.push("btn-variant-" + this.variant);
    if (this.size) {
      classes.push(`btn-size-${this.size}`);
    }

    if (this.iconLeft) {
      classes.push('btn-icon-left');
    }

    return classes.join(' ');
  }
}
