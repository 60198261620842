import { Component } from '@angular/core';
import { AlertService } from '@app/core/services/alert.service';
import { fadeInOutAnimation } from '@app/shared/helpers/animations';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from './alert.types';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [fadeInOutAnimation]
})
export class AlertComponent {
  alerts: Alert[] = [];
  private subscription: Subscription;

  constructor(private alertService: AlertService) { }

private _addAlert(alert: Alert) {
    this.alerts.push(alert);

    if (alert.timeout !== 0) {
      setTimeout(() => this.close(alert), alert.timeout);
    }
  }

 ngOnInit() {
    this.subscription = this.alertService.getObservable().subscribe(alert => this._addAlert(alert));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(Alert: Alert) {
    this.alerts = this.alerts.filter(notif => notif.id !== Alert.id);
  }

  className(Alert: Alert): string {

    let style: string;

    switch (Alert.type) {

      case AlertType.success:
        style = 'success';
        break;

      case AlertType.warning:
        style = 'warning';
        break;

      case AlertType.danger:
        style = 'danger';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }
}
