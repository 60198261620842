<div class="app-input-group" [ngStyle]="{'display': hidden ? 'none' : 'flex'}">
  <label *ngIf="label">{{label}} <span class="label-required">*</span></label>
  <div class="app-input-addons" [ngClass]="{'addon-icon-left': iconLeft}">
    <input #input class="app-input {{ additionalClasses }}" [ngClass]="{'with-addon': ['password', 'date', 'copy', 'time', 'icon'].includes(type)}"
           *ngIf="type !== 'textarea'"
           [attr.type]="type === 'password' ? isPasswordVisible ? 'text' : 'password' : ['date', 'number', 'time'].includes(type) ? 'text': type"
           [attr.placeholder]="placeholder"
           [attr.disabled]="disabled"
           [attr.readonly]="readonly"
           [(ngModel)]="value"
           (ngModelChange)="onChange($event)"
           (focus)="onFocus()"
           (blur)="onBlur()"
           [hidden]="type === 'file'"
           [attr.accept]="fileType"
           [mask]="mask"
           [prefix]="maskPrefix"
           [suffix]="maskSuffix"
           [specialCharacters]="specialCharacters"
           [dropSpecialCharacters]="dropSpecialCharacters"
    >
    <div *ngIf="type === 'file'" class="app-input text-gray-5 d-flex gap-3 flex-wrap-reverse flex-sm-nowrap align-items-center">
      <app-button (click)="chooseFile()" size="sm" color="secondary">Choose file...</app-button>
      <div class="fileNameWrapper">{{ fileName ?? 'File has not been selected...' }}</div>
    </div>

    <textarea class="app-input {{ additionalClasses }}"
              *ngIf="type === 'textarea'"
              [attr.placeholder]="placeholder"
              [attr.disabled]="disabled"
              [attr.readonly]="readonly"
              [(ngModel)]="value"
              (ngModelChange)="onChange($event)"
              (blur)="onTouched()"
    ></textarea>

    <div *ngIf="type == 'password'" class="input-addon-icon">
      <app-button (click)="changePasswordVisibility()" [icon]="isPasswordVisible ? 'visibility_off' : 'visibility'"
                  iconType="outlined" variant="link"></app-button>
    </div>

    <div *ngIf="type == 'copy'" class="input-addon-icon">
      <app-button (click)="copyToClipboard()" [icon]="copying ? 'check' : 'copy'" iconType="outlined"
                  variant="link"></app-button>
    </div>

    <div *ngIf="type == 'date'" class="input-addon-icon" (click)="isDatePickerVisible = !isDatePickerVisible">
      <app-icon class="text-gray-6">event</app-icon>
    </div>

    <div *ngIf="type == 'time'" class="input-addon-icon">
      <app-icon class="text-gray-6">schedule</app-icon>
    </div>

    <div *ngIf="icon" class="input-addon-icon">
      <app-icon class="text-gray-6">{{icon}}</app-icon>
    </div>

    <ng-container *ngIf="type === 'date'">
      <app-datepicker (activeChange)="onDatepickerActiveChange($event)" [active]="isDatePickerVisible"
                      [minDate]="minDate" [maxDate]="maxDate" [date]="selectedDate" (dateChange)="onDateChange($event)">
      </app-datepicker>
    </ng-container>
  </div>
</div>
