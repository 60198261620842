import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { CalendarHeader } from './calendar-header.component';
import { DateUtil } from "@shared/helpers/date-util";

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnChanges {
  @ViewChild('datepicker') el:ElementRef<HTMLElement>;

  @Input()
  public minDate: Date | null;

  @Input()
  public relative: boolean = false;

  @Input()
  public maxDate: Date | null;

  @Input()
  public date: Date;

  @Input()
  public notWorkingDays: string[];

  @Input()
  public active: boolean = false;

  @Output()
  public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  public calendarHeader = CalendarHeader;

  onDateChange(value: any) {
    this.date = new Date(value);
    this.dateChange.emit(value);
  }

  close() {
    this.active = false;
    this.activeChange.emit(false);
  }

  // if need unactive specific days: add [dateFilter]="dateFilter" to mat-calendar
  dateFilter = (date: Date): boolean => {
    const day = date.getDay();
    let result = day !== 0 && day !== 6;

    if (result && this.notWorkingDays && this.notWorkingDays.length > 0) {
      const formattedDate = DateUtil.getFormatShortDate(date);
      result = !this.notWorkingDays.includes(formattedDate);
    }

    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && this.active && this.el) {
      setTimeout(() => {
        const rect = this.el.nativeElement.getBoundingClientRect();
          if(!(
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )) {
          window.scroll({
            top: rect.bottom + window.scrollY,
            left: 0,
            behavior: "smooth"
          });
        }
        }, 200);
      }
  }
}
