export class UserModel {
  firstname: string;
  lastname: string;
  email: string;
  token: string;
  roles: string;
  masterGarden: string;
  masterGardenId: string;
  privateGarden: string;
  privateGardenId: string;
  actAsOrganisation: boolean;
  isImpersonateMode: boolean;
}
