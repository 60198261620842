import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ValidationService } from './services/validation.service';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    ValidationService,
    CurrencyPipe
  ]
})
export class CoreModule { }
