import {Component, Input} from '@angular/core';
import {fadeInAnimation} from '@app/shared/helpers/animations';
import {iconSize} from '../icon/icon.types';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeInAnimation]
})
export class LoaderComponent {
  @Input() isLoading: boolean = true;
  @Input() size: iconSize;

  constructor() {}

  getStyles() {
    let styles: string[] = [];
    if (this.size) {
      styles.push(`font-size:${this.size}`);
    }
    return styles.join(';');
  }
}
