import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {fadeInOutAnimation} from '@shared/helpers/animations';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
declare let gtag: Function;
import {environment} from '@environment/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeInOutAnimation]
})
export class AppComponent implements OnInit {

  static isBrowser = new BehaviorSubject<boolean | undefined>(undefined);
  isGreenieEnable = environment.isGreenieEnabled;

  constructor(@Inject(PLATFORM_ID) platformId: string, private router: Router,
              private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit() {
    this.setUpAnalytics();
    this.setUpMouseflow();
  }

  setUpMouseflow() {
    AppComponent.isBrowser.subscribe(isBrowser => {
      if(isBrowser) {
        if (environment.production) {
          let script = this._renderer2.createElement('script');
          script.type = `text/javascript`;
          script.text = `
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/9b99f0dd-951b-47e7-b3f9-46f6ce68abc9.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();
        `;
          this._renderer2.appendChild(this._document.body, script);
        }
      }
    });
  }

  setUpAnalytics() {
    if (environment.googleTagId != '') {
      this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      ).subscribe(event => {
        /*        gtag('event', 'page_view', {
                  page_path: event.urlAfterRedirects
                })*/
        gtag('config', environment.googleTagId,
          {
            'page_path': event.urlAfterRedirects
          }
        );
        /* gtag('config', environment.googleTagId,
           {'page_path': event.urlAfterRedirects}
         );*/
      })
    }

  }
}
