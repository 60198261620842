import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modals: any[] = [];
  constructor() {}

  setTitle(id: string, title: string) {
    const modal = this.modals.find(x => x.id === id);
    modal.title = title;
  }

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find(x => x.id === id);
    modal.open();
  }

  closeAll() {
    for (let modal of this.modals) {
      modal.isOpen = false;
    }
  }

  close(id: string, withAction: boolean = false) {
    const modal = this.modals.find(x => x.id === id);
    modal.isOpen = false;
    if (withAction && modal.onClose instanceof Function) {
      modal.onClose()
    }
  }

  switchModal(ida: any, idb: string, withOnClose:boolean = false) {
    if (!ida) {
      for (const modal of this.modals) {
        modal.isOpen = false;
        if (withOnClose && modal.onClose instanceof Function) {
          modal.onClose()
        }
      }
    } else {
      const modala = this.modals.find(x => x.id === ida);
      modala.isOpen = false;
      if (withOnClose && modala.onClose instanceof Function) {
        modala.onClose()
      }
    }

    const modalb = this.modals.find(x => x.id === idb);
    modalb.open();
  }

  return(value: any) {
    return new Promise(resolve => resolve(value));
  }
}
