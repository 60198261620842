<div class="app-alerts">
    <div @FadeInOut *ngFor="let alert of alerts" class="app-alert" [ngClass]="className(alert)">
        <ng-container *ngTemplateOutlet="alertTpl;context:{alert:alert}"></ng-container>
    </div>
</div>

<ng-template #alertTpl let-alert="alert">
    <app-icon class="app-alert-icon">{{alert.type}}</app-icon>
    <div class="app-alert-message" [innerHTML]="alert.message"></div>

    <app-button variant="link" class="app-alert-close p-2" (click)="close(alert)">
        <app-icon>close</app-icon>
    </app-button>
</ng-template>
