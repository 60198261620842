import { Component, ContentChildren, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { fadeInAnimation, fadeInOutAnimation } from '@app/shared/helpers/animations';
import { Subscription } from 'rxjs';
import { ControlMessageComponent } from './control-message.component';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
  animations: [fadeInAnimation]
})
export class ControlComponent implements OnInit, OnDestroy {
  @Input() form: any;
  @Input() for: string;
  @Input() description: string;
  public isRequired: boolean;
  public isValid: boolean;
  public _errorMessage: string;
  public control: AbstractControl;

  private controlSubscription: Subscription;

  @ContentChildren(ControlMessageComponent)
  messagesComponentsGroup: QueryList<ControlMessageComponent> = new QueryList<ControlMessageComponent>();

  ngOnInit(): void {
    this.control = this.form.get(this.for);
    this.isControlRequired();

    this.controlSubscription = this.control.valueChanges.subscribe(value => {
      this.isControlRequired();
    })
  }

  isControlRequired() {
    if (!this.control?.validator) {
      this.isRequired = false;
    } else {
      const validator = this.control.validator({} as AbstractControl);
      this.isRequired = validator && validator.required;
    }
  }

  ngOnDestroy(): void {
    if(this.controlSubscription) this.controlSubscription.unsubscribe();
  }

  get errorMessage() {
    for (let propertyName in this.control?.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        const customMessages = this.messagesComponentsGroup.map(msgCmp => msgCmp.message);
        if (customMessages.length) return customMessages[0];

        if (typeof this.control.errors[propertyName] === 'string') {
          return this.control.errors[propertyName];
        }

        return ValidationService.getMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }

    return null;
  }
}
