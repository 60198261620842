export class Alert {
    constructor(
        public id: number,
        public type: AlertType,
        public message: string,
        public timeout: number,
    ) { }
}

export enum AlertType {
    success = 'done',
    warning = 'warning_amber',
    danger = 'error_outline',
    info = 'info'
}