import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import { AppComponent } from '@app/app.component';
import {ModalService} from '@app/core/services/modal.service';
import {fadeInOutAnimation} from '@app/shared/helpers/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [fadeInOutAnimation]
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() title: string = '';
  @Input() id: string = '';
  @Input() isOpen: boolean = false;
  @Input() onClose: Function;
  @Input() onOpen: Function;
  @Input() transparent: boolean = false;
  @Input() canClose: boolean = true;

  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        document.body.appendChild(this.element);
      }
    });

    this.element.addEventListener('click', (el: { target: { className: string; }; }) => {
      if (el.target.className === 'app-modal') {
        this.close();
      }
    });

    if (this.isOpen) {
      this.open();
    }

    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.isOpen = false;
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.isOpen = true;
    if (this.onOpen) {
      this.onOpen();
    }
  }

  close(): void {
    if (!this.canClose) {
      return;
    }

    if (this.onClose) {
      this.onClose();
    }

    this.isOpen = false;
  }
}
