export class DateUtil {
  public static getFormatShortDate(date: Date): string {
    date = this.getDate(date)
    return date.toISOString().split('T')[0];
  }

  public static getDate(date: Date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000));

    return date;
  }

  public static formatDate(date:Date) {
    date = this.getDate(date);
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  private static padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
  }
}
