<app-modal id="auth-modal-without-continue-logout">
  <div class="auth-modal-wrapper">
    <div class="login-side">
      <h4 class="text-center text-gray-6">Sign In</h4>
      <form (submit)="onSubmit($event)" [formGroup]="loginForm" class="login-form" autocomplete="off">
        <app-control [form]="loginForm" for="email">
          <app-input label="Email" formControlName="email"></app-input>
        </app-control>
        <app-control [form]="loginForm" for="password">
          <app-input label="Password" type="password" formControlName="password"></app-input>
        </app-control>

        <div class="mt-3 d-flex flex-column gap-3 justify-content-center">
          <app-button [isLoading]="isLoggingIn" style="width: 200px;" class="mx-auto" size="lg" type="submit" color="secondary">Sign In</app-button>
          <app-button variant="link" color="secondary" routerLink="/login/password-recovery">Forgot my password</app-button>
        </div>
      </form>

    </div>
    <div class="anon-register-side">
      <h5 class="text-center text-gray-6">I don't have an account</h5>
      <div class="d-flex flex-column align-items-center gap-2">
        <app-button (click)="next('register')" style="min-width: 200px" color="secondary" variant="outlined">Sign Up</app-button>

        <div class="mt-4 text-gray-6">Why it is worth to create your own account?</div>
        <div class="extras">
          <div class="extras-item">
            <app-icon>check</app-icon>
            <span>Grow your own Forest Garden</span>
          </div>
          <div class="extras-item">
            <app-icon>check</app-icon>
            <span>Create environmental campaigns</span>
          </div>
          <div class="extras-item">
            <app-icon>check</app-icon>
            <span>Share your environmental impact</span>
          </div>
          <div class="extras-item">
            <app-icon>check</app-icon>
            <span>Compete with other members</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
