export const environment = {
  production: false,
  isGreenieEnabled: true,
  apiUrl: 'https://api-pig-dev.evoqai.com/pig2/',
  backOfficeUrl: 'https://crm-pig-dev.evoqai.com/',
  stripePublishableKey: 'pk_test_51HdwYcEOnYB2nTMFlZymS400HtYLf2Qp2KypuErDZQY0csmp3RvwW8PxrfGEDkC4Dkroqn1HkBrjsQwh5LMQX6KU00dGPFqhEq',
  paymentReturnUrl: 'https://pig-dev.evoqai.com/register/summary',
  homeUrl: 'https://pig-dev.evoqai.com/',
  wordPressUrl: 'https://pig-home.evoqai.com/',
    titlePrefix: "Play It Green - ",
      googleTagId:""
};
