import { Injectable } from '@angular/core';
import { ShoppingCartItem } from '@app/shared/models/shopping-cart-item.model';
import {Observable, Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {RequestService} from "@app/core/services/request.service";
import {ShopSummaryOrderModel} from "@shared/models/shop-summary-order.model";
import { AlertService } from './alert.service';
import { ShopService } from './shop.service';
import {ShopItemType} from "@shared/const/shop-item-type-enum";

@Injectable({
  providedIn: 'root'
})
export class CartService  extends RequestService{
  public cartItems: ShoppingCartItem[] = [];
  public cartItemsSubject: Subject<ShoppingCartItem[]> = new Subject();
  public summaryOrderSubject: Subject<ShopSummaryOrderModel> = new Subject();
  public basketHashSubject: Subject<string> = new Subject();
  private url = super.apiUrl;

  public loaderInCart: boolean = false;

  constructor(
      private http: HttpClient,
      private alertService: AlertService,
      private shopService: ShopService
    ) {
    super();
  }

  getCartItems() {
    let basketHash = sessionStorage.getItem('basketHash');
    if(basketHash != null){

      this.http.post(this.url+'basket/products', {'hash':basketHash}, this.httpOptionsWithCredentials).subscribe((res:any) => {
        this.cartItemsSubject.next(res.list);
        this.summaryOrderSubject.next(res.basketSummaryDto);
        this.cartItems = res.list;
      }, err => {
        this.cartItems = []
        this.cartItemsSubject.next(this.cartItems);
        if (err.status === 400) { //basket wrong or not active anymore, remove it
          this.clearBasketHash();
        }
      })
    } else {
      this.cartItemsSubject.next([])
    }
  }

  isExistInBasket(item:any):boolean {
    return !!this.cartItems?.length && !!this.cartItems.find(cartItem => cartItem.hash === item.hash);
  }

  findItemInBasket(productHash?:string):ShoppingCartItem | undefined {
    return this.cartItems.find(cartItem => cartItem.hash === productHash);
  }

  isGiftCard(item: ShoppingCartItem) {
    return item.type == ShopItemType.GIFT_CARD;
  }

  addItem(item:any) {
    let basketHash = sessionStorage.getItem('basketHash');
    this.cartItems = [...this.cartItems, item];
    this.cartItemsSubject.next(this.cartItems);
    if (basketHash != null) {
      this.addProductToBasket(item,basketHash);
    } else {
      this.loaderInCart = true;
      this.shopService.createBasket().subscribe(
        next => {
          this.loaderInCart = false;
          sessionStorage.setItem('basketHash', next.value);
          this.addProductToBasket(item, next.value);
        },
        error => {
          this.loaderInCart = false;
          this.alertService.danger('Error occured while adding item to cart, try again later.', 3000);
          this.getCartItems();
        }
      )
    }
  }

  private addProductToBasket(item:any, basketHash: string) {
    var request = {
      'productHash': item.hash,
      'basketHash': basketHash,
      'quantity': 1,
    }
    if(item.quantity){
      request.quantity = item.quantity
    }
    this.loaderInCart = true;
    this.shopService.addProductToBasket(request).subscribe(
      next => {
        this.loaderInCart = false;
        this.alertService.success('Product has been added to your cart.');
      },
      error => {
        this.loaderInCart = false;
        this.getCartItems();
        this.alertService.danger('Error occured while adding item to cart, try again later.', 3000);
      }
    )
  }

  getBasketHash(): string {
    let basketHash = sessionStorage.getItem('basketHash');
    if(basketHash != null){
      this.basketHashSubject.next(basketHash);
    }
    return basketHash != null ? basketHash : '';
  }

  clearBasketHash(): void {
    this.cartItems = [];
    sessionStorage.removeItem('basketHash');
  }

  removeCartItem(cartItem:any, basketHash: String) {
    this.cartItems = this.cartItems.filter(item => item.hash !== cartItem.hash);
    this.cartItemsSubject.next(this.cartItems);
    this.deleteProductFromBasket({"basketHash":basketHash, "productHash": cartItem.hash}).subscribe( next => {}, err => {
      this.alertService.danger('Error occured while removing item from cart, try again later.', 3000);
      this.getCartItems();
    })
  }

  applyDiscount(request: any) {
    return this.http.post<void>(
      `${this.url}basket/apply_discount_code`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }
  removeDiscount(request: any) {
    return this.http.post<void>(
      `${this.url}basket/remove_discount_code`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  deleteProductFromBasket(request : any): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/delete_product`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }


  changeQuantityProductInBasket(request : any): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/change_quantity_product`,
      request,
      this.httpOptionsJsonWithoutCredentials
    );
  }

  selectGoodCause(goodCauseId: number) {
    let basketHash = sessionStorage.getItem('basketHash');
    return this.http.post<void>(
      `${this.url}basket/select_good_cause`,
      {basketHash: basketHash, goodCause: goodCauseId},
      this.httpOptionsJsonWithoutCredentials
    );
  }

  selectDirectDebit(hash: string) {
    let basketHash = sessionStorage.getItem('basketHash');
    return this.http.post<void>(
      `${this.url}basket/select_direct_debit`,
      {hash: basketHash, directDebitHash: hash },
      this.httpOptionsJsonWithoutCredentials
    );
  }

  selectCreditCard(cardHash: string) {
    let basketHash = sessionStorage.getItem('basketHash');
    return this.http.post<void>(
      `${this.url}basket/select_credit_card`,
      {hash: basketHash, cardHash: cardHash },
      this.httpOptionsJsonWithoutCredentials
    );
  }

  selectCreditCardWithBasketHash(basketHash: string,cardHash: string) {
    return this.http.post<void>(
      `${this.url}basket/select_credit_card`,
      {hash: basketHash, cardHash: cardHash },
      this.httpOptionsJsonWithoutCredentials
    );
  }

  selectGiftCard(cardHash: string) {
    let basketHash = sessionStorage.getItem('basketHash');
    return this.http.post<void>(
      `${this.url}basket/select_gift_card`,
      {basketHash: basketHash, giftCardHash: cardHash },
      this.httpOptionsJsonWithoutCredentials
    );
  }

  setUserInBasket() {
    let basketHash = sessionStorage.getItem('basketHash');
    if(basketHash){
      let request = {'hash':basketHash}
      return this.http.post<void>(
        `${this.url}basket/set_user_basket`,
        request,
        this.httpOptionsWithCredentials
      ).subscribe();
    }
  }

  selectBillingAddress(basketHash: string, addressHash: string): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/select_billing_address`,
      { basketHash: basketHash, addressHash: addressHash },
      this.httpOptionsWithCredentials
    );
  }

  selectDeliveryAddress(basketHash: string, addressHash: string): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/select_delivery_address`,
      { basketHash: basketHash, addressHash: addressHash },
      this.httpOptionsWithCredentials
    );
  }

  removeDeliveryAddress(request: any): Observable<void> {
    return this.http.post<void>(
      `${this.url}basket/remove_selected_delivery_address`,
      request,
      this.httpOptionsWithCredentials
    );
  }

  calculateNewQuantity(hashProduct: any, plusQuantity:  number): number {
    let currentProduct = this.getProductFromBasket(hashProduct);
    if(currentProduct != null) {
      return currentProduct.quantity + plusQuantity;
    } else {
      return plusQuantity;
    }

  }

  getProductFromBasket(hash: string) {
    let productsInBasket = this.cartItems.filter(productInBasket => {
      return productInBasket.hash == hash;
    });

    if (productsInBasket.length != 0) {
      return productsInBasket[0];
    } else {
      return null;
    }
  }
}
